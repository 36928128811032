import React from "react";
import { Spinner } from "@material-tailwind/react";

const Loading = () => {
    return (
        <div class="w-full h-screen bg-gradient-to-t from-sand to-sky flex flex-col justify-center items-center">
            <Spinner class="w-16 h-16 text-special/50" />
            <p class="text-2xl font-sans font-bold text-special mt-4">Loading...</p>
        </div>
    );
};

export default Loading;
