import React from "react";
import { Suspense } from "react";
import Loading from "../components/loading";

const Front = React.lazy(() => import("../components/front"));
const Projects = React.lazy(() => import("../components/projects"));
const Footer = React.lazy(() => import("../components/footer"));

const IndexPage = () => {
    const isSSR = typeof window === "undefined";
    return (
        <>
            {isSSR && (
                <div class="w-full h-auto bg-sand selection:bg-lightsand">
                    <div class="w-full h-3/5 bg-gradient-to-t from-sand to-sky"></div>
                </div>
            )}
            {!isSSR && (
                <Suspense fallback={<Loading />}>
                    <Front />
                    <Projects />
                    <Footer />
                </Suspense>
            )}
        </>
    );
};

export default IndexPage;

export const Head = () => (
    <>
        <title>Ahmed Malanović — Welcome</title>
        <meta name="author" content="Ahmed Malanović" />
        <meta name="description" content="My portfolio website." />
        <meta name="copyright" content="Ahmed Malanović" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ahmed Malanović — Welcome" />
        <meta name="twitter:url" content="https://ahmedmalanovic.ch" />
        <meta name="twitter:description" content="My portfolio website." />
        <meta name="twitter:creator" content="@malanovicahmed" />
        <meta
            name="keywords"
            content="ahmed malanović, ahmed malanovic, ahmed malanovic portfolio, ahmed malanovic twitter, ahmed malanovic x, ahmed malanovic linkedin"
        />

        <meta name="google-site-verification" content="QSpgomTfo0tS3xatDIIoKXd03bzzXqleCKWc_QKHCHk" />
    </>
);
